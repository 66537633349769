<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3">Saling Schedules</b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '92dvh' : '92dvh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <!-- <v-col cols="12" sm="3" style="position: absolute; left: 20px; top: 10px; z-index: 50">
                           
                        </v-col> -->
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                  <v-row class="pa-2" align="center" justify="end">
                    <v-col cols="12" sm="3">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="searchMenu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ml-1"
                            rounded
                            color="teal"
                            style="text-transform: none"
                            @click="compressMenu()"
                          >
                            <v-icon small left>search</v-icon> Search Settings
                            <v-icon small right>expand_more</v-icon></v-btn
                          >
                        </template>
                        <v-card
                          style="
                            background: var(--v-modal-base) !important;
                            border-radius: 20px;
                            width: 500px;
                          "
                        >
                          <v-expand-transition>
                            <v-card-text>
                              <v-list dense>
                                <v-subheader style="font-size: 16px">
                                  <v-icon color="blue-grey" class="mr-2">
                                    location_on
                                  </v-icon>
                                  Loading Ports
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    icon
                                    @click="
                                      (portSearchType = 'Origin'),
                                        (portSearchModal = true)
                                    "
                                  >
                                    <v-icon>add_circle_outline</v-icon>
                                  </v-btn>
                                </v-subheader>
                                <v-list-item
                                  v-for="port in originPorts"
                                  :key="port.id"
                                  style="height: 30px"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip
                                        v-if="port.port && port.port.locode"
                                        outlined
                                        small
                                        style="border: none"
                                      >
                                        <v-avatar size="24" left>
                                          <v-img
                                            contain
                                            v-if="port.port.locode"
                                            :src="`https://cdn.loglive.io/flags/4x3/${port.port.locode
                                              .substring(0, 2)
                                              .toLowerCase()}.svg`"
                                          ></v-img>
                                        </v-avatar>
                                        {{ port.port.locode }}
                                      </v-chip>
                                      <span style="color: grey" class="ml-1">
                                        - {{ port.port.name }}</span
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-btn
                                      color="red"
                                      icon
                                      @click="removePort('Origin', port)"
                                      ><v-icon small>close</v-icon></v-btn
                                    >
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="originPorts.length == 0">
                                  <v-list-item-content class="text-center">
                                    <span style="color: grey; font-size: 12px"
                                      >No ports selected</span
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-subheader style="font-size: 16px">
                                  <v-icon color="blue-grey" class="mr-2">
                                    location_on
                                  </v-icon>
                                  Destination Ports
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    icon
                                    @click="
                                      (portSearchType = 'Destination'),
                                        (portSearchModal = true)
                                    "
                                  >
                                    <v-icon>add_circle_outline</v-icon>
                                  </v-btn>
                                </v-subheader>
                                <v-divider></v-divider>
                                <v-list-item
                                  v-for="port in destinationPorts"
                                  :key="port.id"
                                  style="height: 30px"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip
                                        v-if="port.port && port.port.locode"
                                        outlined
                                        small
                                        style="border: none"
                                      >
                                        <v-avatar size="24" left>
                                          <v-img
                                            contain
                                            v-if="port.port.locode"
                                            :src="`https://cdn.loglive.io/flags/4x3/${port.port.locode
                                              .substring(0, 2)
                                              .toLowerCase()}.svg`"
                                          ></v-img>
                                        </v-avatar>
                                        {{ port.port.locode }}
                                      </v-chip>
                                      <span style="color: grey" class="ml-1">
                                        - {{ port.port.name }}</span
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-btn
                                      color="red"
                                      icon
                                      @click="removePort('Destination', port)"
                                      ><v-icon small>close</v-icon></v-btn
                                    >
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  v-if="destinationPorts.length == 0"
                                >
                                  <v-list-item-content class="text-center">
                                    <span style="color: grey; font-size: 12px"
                                      >No ports selected</span
                                    >
                                  </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                  <v-list-item-action>
                                    <v-icon color="blue-grey">explore</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Direct Only
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-switch
                                      v-model="params.direct"
                                      color="blue"
                                      @change="searchKey++"
                                    ></v-switch>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-row justify="center" class="my-2">
                                  <v-chip
                                    small
                                    class="mx-1"
                                    @click="setDateType('week')"
                                    :color="
                                      selectedDateType == 'week' ? 'teal' : ''
                                    "
                                  >
                                    <v-icon
                                      small
                                      left
                                      v-if="selectedDateType == 'week'"
                                      >check</v-icon
                                    >
                                    Next 7 Days</v-chip
                                  >
                                  <v-chip
                                    small
                                    class="mx-1"
                                    @click="setDateType('2_week')"
                                    :color="
                                      selectedDateType == '2_week' ? 'teal' : ''
                                    "
                                  >
                                    <v-icon
                                      small
                                      left
                                      v-if="selectedDateType == '2_week'"
                                      >check</v-icon
                                    >
                                    Next 2 weeks</v-chip
                                  >
                                  <v-chip
                                    small
                                    class="mx-1"
                                    @click="setDateType('4_week')"
                                    :color="
                                      selectedDateType == '4_week' ? 'teal' : ''
                                    "
                                  >
                                    <v-icon
                                      small
                                      left
                                      v-if="selectedDateType == '4_week'"
                                      >check</v-icon
                                    >
                                    Next 4 weeks</v-chip
                                  >
                                  <v-chip
                                    small
                                    class="mx-1"
                                    @click="setDateType('6_week')"
                                    :color="
                                      selectedDateType == '6_week' ? 'teal' : ''
                                    "
                                  >
                                    <v-icon
                                      small
                                      left
                                      v-if="selectedDateType == '6_week'"
                                      >check</v-icon
                                    >
                                    Next 6 weeks</v-chip
                                  >
                                </v-row>
                                <v-divider></v-divider>
                                <v-subheader style="font-size: 16px">
                                  <v-icon class="mr-2" color="secondary"
                                    >route</v-icon
                                  >
                                  Favourite Routes
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    v-if="activeRoute"
                                    style="text-transform: none"
                                    rounded
                                    :loading="removingFavourite"
                                    @click="removeFavourite"
                                    small
                                    color="red"
                                    icon
                                    outlined
                                    ><v-icon small>remove</v-icon></v-btn
                                  >

                                  <v-btn
                                    class="ml-1"
                                    v-if="activeRoute && routeChanges"
                                    outlined
                                    style="text-transform: none"
                                    rounded
                                    :loading="updatingFavourite"
                                    @click="updateFavourite"
                                    small
                                    color="blue"
                                    icon
                                    ><v-icon small>save</v-icon></v-btn
                                  >
                                  <v-tooltip top v-if="showBookmarkAdd">
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        @click="addToFavourites()"
                                        v-on="on"
                                        :loading="loadingAddFavourite"
                                      >
                                        <v-icon>bookmark_add</v-icon>
                                      </v-btn>
                                    </template>
                                    <span style="font-size: 12px"
                                      >Save route</span
                                    >
                                  </v-tooltip>
                                </v-subheader>
                                <v-divider></v-divider>
                                <v-progress-linear
                                  v-if="loadingFavourites"
                                  color="primary"
                                  indeterminate
                                ></v-progress-linear>
                                <v-list-item
                                  v-if="
                                    !loadingFavourites && favourites.length == 0
                                  "
                                >
                                  <v-list-item-content class="text-center">
                                    <span style="color: grey; font-size: 12px"
                                      >No favorite routes</span
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  v-for="route in favourites"
                                  :key="route.id"
                                  @click="setRoute(route)"
                                  :style="{
                                    'border-left':
                                      activeRoute && activeRoute.id == route.id
                                        ? '3px solid var(--v-primary-base)'
                                        : '',
                                  }"
                                >
                                  <v-list-item-content class="text-center">
                                    <v-row class="pa-2" align="center">
                                      <v-col cols="4" class="my-0 py-0">
                                        <v-chip
                                          v-for="originPort in route.ports.filter(
                                            (x) => x.type == 'Origin'
                                          )"
                                          class="mt-1"
                                          :key="originPort.id"
                                          outlined
                                          small
                                          style="border: none"
                                        >
                                          <v-avatar size="24" left>
                                            <v-img
                                              contain
                                              v-if="originPort.port.locode"
                                              :src="`https://cdn.loglive.io/flags/4x3/${originPort.port.locode
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"
                                            ></v-img>
                                          </v-avatar>
                                          {{ originPort.port.locode }}
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="4" class="my-0 py-0">
                                        <v-icon class="mx-2" small
                                          >arrow_forward</v-icon
                                        >
                                      </v-col>
                                      <v-col cols="4" class="my-0 py-0">
                                        <v-chip
                                          v-for="destPort in route.ports.filter(
                                            (x) => x.type == 'Destination'
                                          )"
                                          :key="destPort.id"
                                          outlined
                                          small
                                          style="border: none"
                                          class="mt-1"
                                        >
                                          <v-avatar size="24" left>
                                            <v-img
                                              contain
                                              v-if="destPort.port.locode"
                                              :src="`https://cdn.loglive.io/flags/4x3/${destPort.port.locode
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"
                                            ></v-img>
                                          </v-avatar>
                                          {{ destPort.port.locode }}
                                        </v-chip>
                                      </v-col>
                                    </v-row>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-expand-transition>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4" md="3"> </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        hide-details
                        outlined
                        rounded
                        dense
                        clearable
                        v-model="search"
                        placeholder="Search"
                        prepend-inner-icon="search"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="mx-1">
                                        <v-col class="text-center" cols="12" :sm="header.cols ?? 1"
                                            v-for="(header, index) in headers" :key="index">
                                            <v-row class="mb-1" align="center" justify="center">
                                                <b style="font-size: 14px">{{ header.text }}</b> <v-btn v-if="!header.disableSort" icon small @click="changeSortBy(header.value)">
                                                <v-icon v-if="sortBy != header.value" small
                                                    color="grey">arrow_upward</v-icon>
                                                <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                                                <v-icon v-else small>arrow_upward</v-icon>
                                            </v-btn>
                                            </v-row>
                                            
                                        </v-col>
                                    </v-row> -->
                  <!-- <v-divider></v-divider>
                                    <v-progress-linear v-if="loadingSchedules" color="primary"
                                        indeterminate></v-progress-linear>
                                    <div class="pt-1">
                                        <div class="container" v-if="!loading && filterSchedules.length == 0">
                                            <h3 style="color: grey">No Results Found</h3>
                                        </div>
                                        <v-virtual-scroll v-if="filterSchedules.length > 0" :bench="10"
                                            :items="filterSchedules" height="70vh" item-height="70">
                                            <template v-slot:default="{ item }">
                                                <v-row align="center" class="mx-1" 
                                                    style="cursor:pointer">
                                                    <v-col cols="12" sm="2">
                                                        <v-chip small outlined style="border: none">
                                                            <v-icon @click="expandLegs(item)" left>
                                                                launch
                                                            </v-icon>
                                                            <v-chip outlined style="border: none; font-size: 11px">
                                                                <span>
                                                            {{ item.vesselDescription }} - {{ item.voyage }}</span>
                                                            </v-chip>
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" sm="1">
                                                        <v-chip v-if="item.shippingLine" small class="primaryText--text"
                                                            outlined style="border: none; font-size: 11px">
                                                            <v-avatar size="24"
                                                                :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'"
                                                                left>
                                                                <v-img v-if="item.shippingLine.logo"
                                                                    :src="item.shippingLine.logo" contain></v-img>
                                                                <h3 v-else style="color: white">{{
                                                                    item.shippingLine.alias.charAt(0) }}</h3>
                                                            </v-avatar> {{ item.shippingLine.alias ?? item.carrier }}
                                                        </v-chip>

                                                        <v-chip v-else small class="primaryText--text" outlined
                                                            style="border: none; font-size: 11px">
                                                            <v-avatar size="24" :color="'secondary'" left>
                                                                <h3 style="color: white">{{ item.carrier.charAt(0) }}</h3>
                                                            </v-avatar> {{ item.carrier }}
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" sm="1">
                                                        <v-chip small outlined style="border: none">
                                                                       {{item.source}}
                                                                    </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" sm="1" class="text-left mx-0 px-0">
                                                        <div v-if="item.stackDate" class="py-0 my-0">
                                                            <v-tooltip top v-if="item.stackDate.reeferStackStart"
                                                                class="py-0 my-0">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined
                                                                        style="border: none;padding-top: 0; margin-top:0"
                                                                        v-on="on" class="py-0 my-0">
                                                                        <v-avatar size="12" left
                                                                            :color="item.stackDate.reeferStackStartConfirmed ? 'success' : 'orange'">R</v-avatar>
                                                                        {{ formatDateTime(item.stackDate.reeferStackStart)
                                                                        }}
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Reefer Stacks Open: {{
                                                                    formatDateTime(item.stackDate.reeferStackStart)
                                                                }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip top v-else>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="py-0 my-0">
                                                                        <v-avatar size="12" left
                                                                            :color="'grey'">R</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Reefer Stacks Open:
                                                                    NONE</span>
                                                            </v-tooltip>
                                                            <br v-if="!item.stackDate.reeferStackStart">

                                                            <v-tooltip top v-if="item.stackDate.dryStackStart">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined
                                                                        style="border: none;padding-top: 0; margin-top:0"
                                                                        v-on="on" class="py-0 my-0">
                                                                        <v-avatar size="12" left
                                                                            :color="item.stackDate.dryStackStartConfirmed ? 'success' : 'orange'">D</v-avatar>
                                                                        {{ formatDateTime(item.stackDate.dryStackStart)
                                                                        }}
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Dry Stacks Open: {{
                                                                    formatDateTime(item.stackDate.dryStackStart) }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip top v-else>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="pt-0 mt-0">
                                                                        <v-avatar size="18" left
                                                                            :color="'grey'">D</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Dry Stacks Open: NONE</span>
                                                            </v-tooltip>
                                                            <br v-if="!item.stackDate.dryStackStart">

                                                            <v-tooltip top v-if="item.stackDate.fisStart">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="py-0 my-0">
                                                                        <v-avatar size="18" left
                                                                            :color="item.stackDate.fisStartConfirmed ? 'success' : 'orange'">F</v-avatar>
                                                                        {{ formatDateTime(item.stackDate.fisStart) }}
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">FIS Stacks Open: {{
                                                                    formatDateTime(item.stackDate.fisStart) }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip top v-else>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="pt-0 mt-0">
                                                                        <v-avatar size="18" left
                                                                            :color="'grey'">F</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">FIS Stacks Open: NONE</span>
                                                            </v-tooltip>

                                                        </div>
                                                        <div v-else>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="pt-0 mt-0">
                                                                        <v-avatar size="18" left
                                                                            :color="'grey'">R</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Reefer Stacks Open:
                                                                    NONE</span>
                                                            </v-tooltip>
                                                            <br>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="pt-0 mt-0">
                                                                        <v-avatar size="18" left
                                                                            :color="'grey'">D</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Dry Stacks Open: NONE</span>
                                                            </v-tooltip>
                                                            <br>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on"
                                                                        class="pt-0 mt-0">
                                                                        <v-avatar size="18" left
                                                                            :color="'grey'">F</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">FIS Stacks Open: NONE</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="1" class="text-left mx-0 px-0">
                                                        <div v-if="item.stackDate" class="pt-1">
                                                            <v-tooltip top v-if="item.stackDate.reeferStackFinish">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="item.stackDate.reeferStackFinishConfirmed ? 'success' : 'orange'">R</v-avatar>
                                                                        {{ formatDateTime(item.stackDate.reeferStackFinish)
                                                                        }}
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Reefer Stacks Close: {{
                                                                    formatDateTime(item.stackDate.reeferStackFinish)
                                                                }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip top v-else>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="'grey'">R</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Reefer Stacks Close:
                                                                    NONE</span>
                                                            </v-tooltip>
                                                            <br v-if="!item.stackDate.reeferStackFinish">

                                                            <v-tooltip top v-if="item.stackDate.dryStackFinish">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="item.stackDate.dryStackFinishConfirmed ? 'success' : 'orange'">D</v-avatar>
                                                                        {{ formatDateTime(item.stackDate.dryStackFinish) }}
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Dry Stacks Close: {{
                                                                    formatDateTime(item.stackDate.dryStackFinish) }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip top v-else>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="'grey'">D</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Dry Stacks Close: NONE</span>
                                                            </v-tooltip>
                                                            <br v-if="!item.stackDate.dryStackFinish">

                                                            <v-tooltip top v-if="item.stackDate.fisFinish">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="item.stackDate.fisFinishConfirmed ? 'success' : 'orange'">F</v-avatar>
                                                                        {{ formatDateTime(item.stackDate.fisFinish) }}
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">FIS Stacks Close: {{
                                                                    formatDateTime(item.stackDate.fisFinish) }}</span>
                                                            </v-tooltip>
                                                            <v-tooltip top v-else>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="'grey'">F</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">FIS Stacks Close: NONE</span>
                                                            </v-tooltip>

                                                        </div>
                                                        <div v-else>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="'grey'">R</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Reefer Stacks Close:
                                                                    NONE</span>
                                                            </v-tooltip>
                                                            <br>

                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="'grey'">D</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">Dry Stacks Close: NONE</span>
                                                            </v-tooltip>
                                                            <br>

                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip x-small outlined style="border: none" v-on="on">
                                                                        <v-avatar size="22" left
                                                                            :color="'grey'">F</v-avatar>
                                                                        -
                                                                    </v-chip>
                                                                </template>
                                                                <span style="font-size: 12px">FIS Stacks Close: NONE</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="1">
                                                        <v-chip outlined style="border: none;font-size: 11px" small>
                                                            <v-avatar size="20" left>
                                                                <v-img contain
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                            </v-avatar> {{ item.portOfLoadValue }}
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" sm="1" class="mx-0 px-0">
                                                        <v-tooltip top v-if="item.systemETD">
                                                            <template v-slot:activator="{ on }">
                                                                <v-chip small outlined style="border: none;font-size: 11px"
                                                                    v-on="on">
                                                                    <v-icon color="grey" small
                                                                        left>settings_suggest</v-icon>
                                                                    {{ formatDateTime(item.systemETD) }}
                                                                </v-chip>
                                                            </template>
                                                            <span style="font-size: 12px">Source: RMS</span>
                                                        </v-tooltip>

                                                        <v-chip small outlined style="border: none;font-size: 11px" v-else>
                                                            <v-icon color="grey" small left>schedule</v-icon>
                                                            {{ formatDateTime(item.etd) }}
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" sm="1">
                                                        <v-chip outlined style="border: none;font-size: 11px" small>
                                                            <v-avatar size="20" left>
                                                                <v-img contain
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                            </v-avatar> {{ item.finalDestinationValue }}
                                                        </v-chip>
                                                    </v-col>

                                                    <v-col cols="12" sm="1" class="mx-0 px-0">
                                                        <v-chip small outlined style="border: none;font-size: 11px">
                                                            <v-icon color="grey" small left>schedule</v-icon>
                                                            {{ formatDateTime(item.eta) }}
                                                        </v-chip>
                                                    </v-col>

                                                    <v-col cols="12" sm="1" class="mx-0 px-0 text-center">
                                                        <span>{{ item.transit }}</span>
                                                    </v-col>
                                                    <v-col cols="12" sm="1" :key="legRefreshKey" :class="'text-center'">
                                                        <v-btn small outlined icon :loading="loadingLegs.includes(item.id)"
                                                            :color="item.legs == 1 ? 'success' : 'blue'">
                                                            {{ item.legs }}
                                                        </v-btn>
                                                      
                                                    </v-col>


                                                </v-row>
                                                <v-divider></v-divider>
                                            </template>
                                        </v-virtual-scroll>
                                    </div> -->
                </v-card-text>

                <v-card-text
                  style="max-width: 100%; overflow-x: auto"
                  class="my-0"
                >
                  <div
                    :style="{ width: tableWidth }"
                    v-if="displayData.length > 0"
                  >
                    <table :width="'100%'">
                      <tr class="mx-0 px-0">
                        <td
                          class="text-center mx-0 px-0"
                          style="
                            cursor: grab;
                            border-right: 1px solid grey;
                            min-height: 20px;
                            min-width: 20px;
                          "
                          v-for="(header, index) in headers"
                          :key="index"
                          :width="header.width ?? '150px'"
                        >
                          <div>
                            <v-row align="center" justify="center" class="py-2">
                              <b style="font-size: 12px">{{ header.text }}</b>
                              <v-btn
                                v-if="!header.disableSort"
                                class="ml-1"
                                icon
                                x-small
                                @click="changeSortBy(header.value)"
                              >
                                <v-icon
                                  v-if="sortBy != header.value"
                                  small
                                  color="grey"
                                  >arrow_upward</v-icon
                                >
                                <v-icon v-else-if="sortDesc" small
                                  >arrow_downward</v-icon
                                >
                                <v-icon v-else small>arrow_upward</v-icon>
                              </v-btn>
                              <v-badge
                                v-if="!header.disableFilter"
                                :content="
                                  filters[header.value]
                                    ? filters[header.value].length
                                    : 0
                                "
                                :value="
                                  filters[header.value] &&
                                  filters[header.value].length > 0
                                "
                                overlap
                                color="primary"
                                offset-y="10"
                              >
                                <v-menu
                                  offset-y
                                  left
                                  :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      color="grey"
                                      icon
                                      ><v-icon small>filter_alt</v-icon></v-btn
                                    >
                                  </template>
                                  <v-card
                                    style="
                                      background: var(
                                        --v-modal-base
                                      ) !important;
                                      border-radius: 20px;
                                    "
                                  >
                                    <v-card-text>
                                      <v-list
                                        dense
                                        style="
                                          background: var(
                                            --v-modal-base
                                          ) !important;
                                          border-radius: 20px;
                                        "
                                      >
                                        <v-text-field
                                          hide-details
                                          rounded
                                          dense
                                          outlined
                                          clearable
                                          prepend-inner-icon="search"
                                          placeholder="Search"
                                          autofocus
                                          v-model="searchHeaders[header.value]"
                                          @input="updateSearchKey()"
                                        ></v-text-field>
                                        <v-list
                                          dense
                                          style="
                                            max-height: 50vh;
                                            overflow-y: auto;
                                            background: var(
                                              --v-modal-base
                                            ) !important;
                                          "
                                          :key="searchKey"
                                        >
                                          <v-list-item
                                            v-for="(
                                              item, index
                                            ) in headerOptions(header.value)"
                                            :key="index"
                                          >
                                            <v-list-item-action class="pr-0">
                                              <v-checkbox
                                                v-model="filters[header.value]"
                                                :value="item.value"
                                                hide-details
                                                dense
                                                @change="
                                                  calculateFilteredResults()
                                                "
                                              ></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                {{ item.name }}
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              headerOptions(header.value)
                                                .length == 0
                                            "
                                          >
                                            <v-list-item-content
                                              class="text-center"
                                            >
                                              <span style="font-size: 12px"
                                                >No results found</span
                                              >
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-list>
                                    </v-card-text>
                                  </v-card>
                                </v-menu>
                              </v-badge>
                            </v-row>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <v-divider></v-divider>
                    <div
                      class="mt-1 pt-1 px-1"
                      :style="{ width: '100%' }"
                      v-if="displayData.length > 0"
                    >
                      <v-virtual-scroll
                        v-if="displayData.length > 0"
                        :bench="10"
                        :items="displayData"
                        :height="calculatedHeight"
                        :width="'100%'"
                        item-height="70"
                        class="px-1"
                      >
                        <template v-slot:default="{ item }">
                          <div
                            class="text-center my-0 py-0"
                            :style="{
                              display: 'flex',
                              'flex-direction': 'row',
                              width: '100%',
                              'background-color': 'transparent',
                              'vertical-align': 'middle',
                            }"
                          >
                            <td
                              v-for="(header, index) in headers"
                              :key="index"
                              :style="{ width: header.width ?? '150px' }"
                              class="text-center mt-0 mb-0 pb-0"
                            >
                              <div v-if="header.value == 'action'">
                                <v-row
                                  align="center"
                                  justify="center"
                                  class="py-1"
                                >
                                  <v-btn icon @click="expandLegs(item)"
                                    ><v-icon>launch</v-icon></v-btn
                                  >
                                </v-row>
                              </div>
                              <div
                                v-else-if="header.value == 'portOfLoadValue'"
                                class="text-center"
                              >
                                <v-tooltip top v-if="item.portOfLoadValue">
                                  <template v-slot:activator="{ on }">
                                    <v-chip
                                      outlined
                                      style="border: none; font-size: 11px"
                                      v-on="on"
                                      small
                                      @click="
                                        addToFilter(
                                          item.portOfLoadValue,
                                          'portOfLoadValue'
                                        )
                                      "
                                    >
                                      <v-avatar size="20" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ item.portOfLoadValue }}
                                    </v-chip>
                                  </template>
                                  <span style="font-size: 12px">{{
                                    item.portOfLoadCity
                                  }}</span>
                                </v-tooltip>
                              </div>
                              <div
                                v-else-if="
                                  header.value == 'finalDestinationValue'
                                "
                                class="text-center"
                              >
                                <v-tooltip
                                  top
                                  v-if="item.finalDestinationValue"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-chip
                                      outlined
                                      style="border: none; font-size: 11px"
                                      v-on="on"
                                      small
                                      @click="
                                        addToFilter(
                                          item.finalDestinationValue,
                                          'finalDestinationValue'
                                        )
                                      "
                                    >
                                      <v-avatar size="20" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ item.finalDestinationValue }}
                                    </v-chip>
                                  </template>
                                  <span style="font-size: 12px">{{
                                    item.finalDestinationCity
                                  }}</span>
                                </v-tooltip>
                              </div>
                              <div
                                v-else-if="header.value == 'shippingLineName'"
                                class="text-left"
                              >
                                <v-chip
                                  v-if="item.shippingLine"
                                  small
                                  class="primaryText--text"
                                  outlined
                                  @click="
                                    addToFilter(
                                      item.shippingLineName,
                                      'shippingLineName'
                                    )
                                  "
                                  style="border: none"
                                >
                                  <v-avatar
                                    size="24"
                                    :color="
                                      item.shippingLine &&
                                      item.shippingLine.logo
                                        ? 'white'
                                        : 'secondary'
                                    "
                                    left
                                  >
                                    <v-img
                                      v-if="item.shippingLine.logo"
                                      :src="item.shippingLine.logo"
                                      contain
                                    ></v-img>
                                    <h3 v-else style="color: white">
                                      {{ item.shippingLineName.charAt(0) }}
                                    </h3>
                                  </v-avatar>
                                  {{ item.shippingLineName }}
                                </v-chip>
                              </div>
                              <div v-else-if="header.value == 'etd'">
                                <v-chip
                                  v-if="item[header.value]"
                                  small
                                  class="primaryText--text"
                                  outlined
                                  @click="
                                    addToFilter(
                                      item[header.value],
                                      header.value
                                    )
                                  "
                                  style="border: none"
                                >
                                  {{ formatDate(item[header.value]) }}
                                </v-chip>
                              </div>

                              <div v-else-if="header.value == 'eta'">
                                <v-chip
                                  v-if="item[header.value]"
                                  small
                                  class="primaryText--text"
                                  outlined
                                  @click="
                                    addToFilter(
                                      item[header.value],
                                      header.value
                                    )
                                  "
                                  style="border: none"
                                >
                                  {{ formatDate(item[header.value]) }}
                                </v-chip>
                              </div>

                              <div
                                v-else-if="header.value == 'vesselDescription'"
                                class="text-left"
                              >
                                <v-chip
                                  v-if="item[header.value]"
                                  small
                                  class="primaryText--text"
                                  outlined
                                  @click="
                                    addToFilter(
                                      item[header.value],
                                      header.value
                                    )
                                  "
                                  style="border: none"
                                >
                                  {{ item[header.value] }}
                                </v-chip>
                              </div>
                              <div
                                v-else-if="header.value == 'stacksOpen'"
                                class="text-left"
                              >
                                <div v-if="item.stackDate" class="py-0 my-0">
                                  <ul style="list-style-type: none">
                                    <li>
                                      <v-tooltip
                                        top
                                        v-if="item.stackDate.reeferStackStart"
                                        class="py-0 my-0"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="
                                              border: none;
                                              padding-top: 0;
                                              margin-top: 0;
                                            "
                                            v-on="on"
                                            class="py-0 my-0"
                                          >
                                            <v-avatar
                                              size="12"
                                              left
                                              :color="
                                                item.stackDate
                                                  .reeferStackStartConfirmed
                                                  ? 'success'
                                                  : 'orange'
                                              "
                                              >R</v-avatar
                                            >
                                            {{
                                              formatDateTime(
                                                item.stackDate.reeferStackStart
                                              )
                                            }}
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Reefer Stacks Open:
                                          {{
                                            formatDateTime(
                                              item.stackDate.reeferStackStart
                                            )
                                          }}</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="py-0 my-0"
                                          >
                                            <v-avatar
                                              size="12"
                                              left
                                              :color="'grey'"
                                              >R</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Reefer Stacks Open: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip
                                        top
                                        v-if="item.stackDate.dryStackStart"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="
                                              border: none;
                                              padding-top: 0;
                                              margin-top: 0;
                                            "
                                            v-on="on"
                                            class="py-0 my-0"
                                          >
                                            <v-avatar
                                              size="12"
                                              left
                                              :color="
                                                item.stackDate
                                                  .dryStackStartConfirmed
                                                  ? 'success'
                                                  : 'orange'
                                              "
                                              >D</v-avatar
                                            >
                                            {{
                                              formatDateTime(
                                                item.stackDate.dryStackStart
                                              )
                                            }}
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Dry Stacks Open:
                                          {{
                                            formatDateTime(
                                              item.stackDate.dryStackStart
                                            )
                                          }}</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="pt-0 mt-0"
                                          >
                                            <v-avatar
                                              size="18"
                                              left
                                              :color="'grey'"
                                              >D</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Dry Stacks Open: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip
                                        top
                                        v-if="item.stackDate.fisStart"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="py-0 my-0"
                                          >
                                            <v-avatar
                                              size="18"
                                              left
                                              :color="
                                                item.stackDate.fisStartConfirmed
                                                  ? 'success'
                                                  : 'orange'
                                              "
                                              >F</v-avatar
                                            >
                                            {{
                                              formatDateTime(
                                                item.stackDate.fisStart
                                              )
                                            }}
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >FIS Stacks Open:
                                          {{
                                            formatDateTime(
                                              item.stackDate.fisStart
                                            )
                                          }}</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="pt-0 mt-0"
                                          >
                                            <v-avatar
                                              size="18"
                                              left
                                              :color="'grey'"
                                              >F</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >FIS Stacks Open: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                  </ul>
                                </div>
                                <div v-else>
                                  <ul style="list-style-type: none">
                                    <li>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="pt-0 mt-0"
                                          >
                                            <v-avatar
                                              size="18"
                                              left
                                              :color="'grey'"
                                              >R</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Reefer Stacks Open: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="pt-0 mt-0"
                                          >
                                            <v-avatar
                                              size="18"
                                              left
                                              :color="'grey'"
                                              >D</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Dry Stacks Open: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                            class="pt-0 mt-0"
                                          >
                                            <v-avatar
                                              size="18"
                                              left
                                              :color="'grey'"
                                              >F</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >FIS Stacks Open: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                v-else-if="header.value == 'stacksClose'"
                                class="text-left"
                              >
                                <div v-if="item.stackDate" class="pt-1">
                                  <ul style="list-style-type: none">
                                    <li>
                                      <v-tooltip
                                        top
                                        v-if="item.stackDate.reeferStackFinish"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="
                                                item.stackDate
                                                  .reeferStackFinishConfirmed
                                                  ? 'success'
                                                  : 'orange'
                                              "
                                              >R</v-avatar
                                            >
                                            {{
                                              formatDateTime(
                                                item.stackDate.reeferStackFinish
                                              )
                                            }}
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Reefer Stacks Close:
                                          {{
                                            formatDateTime(
                                              item.stackDate.reeferStackFinish
                                            )
                                          }}</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="'grey'"
                                              >R</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Reefer Stacks Close: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip
                                        top
                                        v-if="item.stackDate.dryStackFinish"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="
                                                item.stackDate
                                                  .dryStackFinishConfirmed
                                                  ? 'success'
                                                  : 'orange'
                                              "
                                              >D</v-avatar
                                            >
                                            {{
                                              formatDateTime(
                                                item.stackDate.dryStackFinish
                                              )
                                            }}
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Dry Stacks Close:
                                          {{
                                            formatDateTime(
                                              item.stackDate.dryStackFinish
                                            )
                                          }}</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="'grey'"
                                              >D</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Dry Stacks Close: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip
                                        top
                                        v-if="item.stackDate.fisFinish"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="
                                                item.stackDate
                                                  .fisFinishConfirmed
                                                  ? 'success'
                                                  : 'orange'
                                              "
                                              >F</v-avatar
                                            >
                                            {{
                                              formatDateTime(
                                                item.stackDate.fisFinish
                                              )
                                            }}
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >FIS Stacks Close:
                                          {{
                                            formatDateTime(
                                              item.stackDate.fisFinish
                                            )
                                          }}</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="'grey'"
                                              >F</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >FIS Stacks Close: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                  </ul>
                                </div>
                                <div v-else>
                                  <ul style="list-style-type: none">
                                    <li>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="'grey'"
                                              >R</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Reefer Stacks Close: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="'grey'"
                                              >D</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >Dry Stacks Close: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                    <li>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-chip
                                            x-small
                                            outlined
                                            style="border: none"
                                            v-on="on"
                                          >
                                            <v-avatar
                                              size="22"
                                              left
                                              :color="'grey'"
                                              >F</v-avatar
                                            >
                                            -
                                          </v-chip>
                                        </template>
                                        <span style="font-size: 12px"
                                          >FIS Stacks Close: NONE</span
                                        >
                                      </v-tooltip>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div v-else>
                                <v-chip
                                  v-if="item[header.value]"
                                  small
                                  class="primaryText--text"
                                  outlined
                                  @click="
                                    addToFilter(
                                      item[header.value],
                                      header.value
                                    )
                                  "
                                  style="border: none"
                                >
                                  {{ item[header.value] }}
                                </v-chip>
                              </div>
                            </td>
                          </div>

                          <v-divider class="my-0 py-0"></v-divider>
                        </template>
                      </v-virtual-scroll>
                    </div>
                  </div>
                  <div
                    class="container"
                    v-if="!loadingSchedules && displayData.length == 0"
                  >
                    <h3 style="color: grey">No Results Found</h3>
                  </div>
                  <div
                    class="container"
                    v-else-if="loadingSchedules && displayData.length == 0"
                  >
                    <valhalla-loading height="500px" />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog v-model="portSearchModal" width="500px">
      <v-card flat class="my-0 py-0" :loading="loadingPortSearch">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Search {{ portSearchType }} Port </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(portSearchModal = false), (searchMenu = true)"
            >X</v-btn
          >
        </v-toolbar>

        <v-card-text class="my-0 py-0">
          <v-text-field
            outlined
            dense
            placeholder="Search"
            clearable
            v-model="portSearch"
            autofocus
            prepend-inner-icon="search"
          ></v-text-field>
          <v-virtual-scroll
            v-if="portResults.length > 0"
            class="my-0 py-0"
            :bench="0"
            :items="portResults"
            height="250"
            item-height="50"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.id" @click="setPort(item)">
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="item.locode"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ item.locode }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list-item v-else>
            <v-list-item-content class="text-center">
              <span style="color: grey; font-size: 12px">No results found</span>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="scheduleModal"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="selectedSchedule">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ selectedSchedule.vesselDescription }} -
            {{ selectedSchedule.voyage }}
            <v-chip small outlined style="border: none" class="ml-2">
              <v-avatar size="22" left>
                <v-img
                  contain
                  :src="`https://cdn.loglive.io/flags/4x3/${selectedSchedule.portOfLoadValue
                    .substring(0, 2)
                    .toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
              {{ selectedSchedule.portOfLoadValue }}
            </v-chip>
            <v-icon small class="mx-1">arrow_forward</v-icon>
            <v-chip small outlined style="border: none">
              <v-avatar size="22" class="ml-1" left>
                <v-img
                  contain
                  :src="`https://cdn.loglive.io/flags/4x3/${selectedSchedule.finalDestinationValue
                    .substring(0, 2)
                    .toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
              {{ selectedSchedule.finalDestinationValue }}
            </v-chip>
            <v-chip class="ml-2">
              {{ selectedSchedule.transit }} Day(s)
            </v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="scheduleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <SailingSchedule :selectedSchedule="selectedSchedule" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment";
import dateFormat from "dateformat";
import SailingSchedule from "../components/SystemComponents/SailingSchedule.vue";
export default {
  components: {
    SailingSchedule,
  },
  data: () => ({
    activeRoute: null,
    carriers: [],
    compressedMenu: false,
    
    datePicker: false,
    destination: null,
    destinationList: [],
    destinationPorts: [],
    expanded: [],
    favourites: [],
    filteredResults: [],
    filters: {},
    headers: [
      {
        text: "",
        value: "action",
        align: "center",
        width: "50px",
        disableSort: true,
        disableFilter: true,
        sortable: false,
      },
      {
        text: "Vessel",
        value: "vesselDescription",
        cols: "2",
        align: "left",
      },
      {
        text: "Voyage",
        value: "voyage",
        width: "125px",
        align: "left",
      },
      // {
      //     text: 'Voyage',
      //     value: 'voyage',
      //     align: 'left',
      // },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "center",
      },
      {
        text: "Source",
        value: "source",
        align: "center",
        width: "100px",
      },
      // {
      //     text: 'Service',
      //     value: 'serviceCode',
      //     align: 'left',
      // },
      {
        text: "Stacks Open",
        value: "stacksOpen",
        align: "left",
        width: "180px",
        disableSort: true,
        disableFilter: true,
      },
      {
        text: "Stacks Close",
        value: "stacksClose",
        align: "left",
        width: "180px",
        disableSort: true,
        disableFilter: true,
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
      },
      {
        text: "ETD",
        value: "etd",
        align: "center",
      },
      {
        text: "Destination",
        value: "finalDestinationValue",
        align: "center",
      },
      {
        text: "ETA",
        value: "eta",
        align: "center",
      },
      {
        text: "Transit",
        value: "transit",
        align: "center",
      },
      {
        text: "Legs",
        value: "legs",
        align: "center",
      },
    ],
    legRefreshKey: 3000,
    loading: false,
    loadingAddFavourite: false,
    loadingCarriers: false,
    loadingFavourites: false,
    loadingLegs: [],
    loadingSchedules: false,
    loadingScheduleLegs: false,
    isLoading: false,
    loadingPortSearch: false,

    origin: null,
    originPorts: [],
    points: [],
    portSearchTimeout: null,
    portSearchQuery: null,
    portResults: [],
    portSearch: null,
    portSearchModal: false,
    portSearchType: null,
    originList: [],
    params: {
      dates: [],
      direct: false,
    },
    removingFavourite: false,
    routeChanges: false,
    scheduleModal: false,
    scheduleSearchQuery: null,
    scheduleSearchTimeout: null,
    search: null,
    searchMenu: true,
    searchKey: 2000,
    searchHeaders: {},
    selectedSchedule: null,
    selectedDateType: "2_week",
    selectedCarriers: [],
    schedules: [],
    sortBy: null,
    sortDesc: false,
    updatingFavourite: false,
    updateFilterValue: 100,
  }),
  computed: {
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    displayData() {
      let result = this.filteredResults;
      //   if (this.search) {
      //     result = result.filter(
      //       (x) =>
      //         (x.orderNo &&
      //           x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
      //         (x.shipperName &&
      //           x.shipperName
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.consigneeName &&
      //           x.consigneeName
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.shippingLineName &&
      //           x.shippingLineName
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.vesselVoyage &&
      //           x.vesselVoyage
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.regimeCode &&
      //           x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
      //         (x.portOfLoadValue &&
      //           x.portOfLoadValue
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.portOfLoadCity &&
      //           x.portOfLoadCity
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.finalDestinationValue &&
      //           x.finalDestinationValue
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.finalDestinationCity &&
      //           x.finalDestinationCity
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.carrierReferenceNumber &&
      //           x.carrierReferenceNumber
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.shipperReferenceNo &&
      //           x.shipperReferenceNo
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //         (x.consigneeReferenceNo &&
      //           x.consigneeReferenceNo
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase())) ||
      //             (x.customerName &&
      //           x.customerName
      //             .toLowerCase()
      //             .includes(this.search.toLowerCase()))
      //     );
      //   }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    tableWidth() {
      let result = 0;
      this.headers.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
    filterSchedules() {
      let result = this.schedules;
      if (this.selectedCarriers.length > 0) {
        result = result.filter((x) =>
          this.selectedCarriers.includes(x.shippingLineId)
        );
      }
      if (this.search) {
        result = result.filter(
          (x) =>
            x.vesselDescription
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            x.voyage.toLowerCase().includes(this.search.toLowerCase()) ||
            x.carrier.toLowerCase().includes(this.search.toLowerCase()) ||
            x.serviceCode.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    showBookmarkAdd() {
      let result = false;
      if (this.originPorts.length > 0 && this.destinationPorts.length > 0) {
        if (!this.activePort) {
          result = true;
        }
      }
      return result;
    },
  },
  async mounted() {
    this.setDateType("2_week");
  },
  created() {
    this.resetFilters();
    this.params.searchDate = this.formatDate(new Date());
    this.getFavouriteRoutes();
    this.getCarriers();
  },
  watch: {
    async searchDestination(val) {
      if (val && val.length > 2) {
        this.isLoading = true;
        this.destinationList = await this.searchPlaces({
          params: {
            search: val,
          },
        });
        this.destinationList.forEach((x) => {
          x.description = [
            x.locationName,
            x.unLocode,
            x.countryName,
            x.subdivisionName,
          ].join("");
        });
        this.isLoading = false;
      }
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: "port",
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 300);
    },
    searchKey() {
      if (this.originPorts.length > 0 && this.destinationPorts.length > 0) {
        this.expanded = [];
        this.getSchedules();
      }
    },
  },
  methods: {
    async addToFavourites() {
      this.loadingAddFavourite = true;
      let result = await this.$API.createFavouriteRoute({
        ports: [
          ...this.originPorts.map((x) => ({
            type: "Origin",
            portId: x.port.id,
          })),
          ...this.destinationPorts.map((x) => ({
            type: "Destination",
            portId: x.port.id,
          })),
        ],
        // originId: this.origin.id,
        // destinationId: this.destination.id
      });
      this.getFavouriteRoutes();
      // result.origin = this.origin
      // result.destination = this.destination
      // this.favourites.push(result)
      this.loadingAddFavourite = false;
    },
    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "scheduleFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.calculateFilteredResults();
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
    },
    B1: function (t) {
      return t * t * t;
    },
    B2: function (t) {
      return 3 * t * t * (1 - t);
    },
    B3: function (t) {
      return 3 * t * (1 - t) * (1 - t);
    },
    B4: function (t) {
      return (1 - t) * (1 - t) * (1 - t);
    },
    bezierCurve(latlong1, latlong2, latlong3, latlong4, resolution) {
      var lat1 = latlong1.lat();
      var long1 = latlong1.lng();
      var lat2 = latlong2.lat();
      var long2 = latlong2.lng();
      var lat3 = latlong3.lat();
      var long3 = latlong3.lng();
      var lat4 = latlong4.lat();
      var long4 = latlong4.lng();

      var points = [];

      for (let it = 0; it <= 1; it += resolution) {
        points.push(
          this.getBezier(
            {
              x: lat1,
              y: long1,
            },
            {
              x: lat2,
              y: long2,
            },
            {
              x: lat3,
              y: long3,
            },
            {
              x: lat4,
              y: long4,
            },
            it
          )
        );
      }
      var path = [];
      for (var i = 0; i < points.length - 1; i++) {
        path.push(new this.google.maps.LatLng(points[i].x, points[i].y));
        path.push(
          new this.google.maps.LatLng(points[i + 1].x, points[i + 1].y, false)
        );
      }

      //   var Line = new this.google.maps.Polyline(

      //   );

      //   Line.setMap(map);

      return {
        path: path,
        geodesic: true,
        strokeColor: "#35495e",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            },
            offset: "0",
            repeat: "20px",
          },
        ],
      };
    },
    getBezier(C1, C2, C3, C4, percent) {
      var pos = {};
      pos.x =
        C1.x * this.B1(percent) +
        C2.x * this.B2(percent) +
        C3.x * this.B3(percent) +
        C4.x * this.B4(percent);
      pos.y =
        C1.y * this.B1(percent) +
        C2.y * this.B2(percent) +
        C3.y * this.B3(percent) +
        C4.y * this.B4(percent);
      return pos;
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.schedules];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    async changeFavouriteStatus(id) {
      this.updatingFavourite = true;
      if (!this.favourited(id)) {
        await this.$API.createFavouriteRoute({
          unLocodeId: id,
        });
        this.getFavouriteRoutes();
      } else {
        let find = this.favourites.find((x) => x.unLocodeId === id);
        await this.$API.updateFavouriteRoute({
          id: find.id,
          isActive: true,
          isDeleted: false,
        });
        let index = this.favourites.indexOf(find);
        this.favourites.splice(index, 1);
      }
      this.updatingFavourite = false;
    },
    compressMenu() {
      this.compressedMenu = !this.compressedMenu;
    },
    drawCurve(P1, P2, map) {
      console.log(this.google.maps.Data.Geometry);
      var lineLength =
        this.google.maps.Data.Geometry.Spherical.computeDistanceBetween(P1, P2);
      var lineHeading = this.google.maps.Data.Geometry.Spherical.computeHeading(
        P1,
        P2
      );
      let lineHeading1, lineHeading2;
      if (lineHeading < 0) {
        lineHeading1 = lineHeading + 45;
        lineHeading2 = lineHeading + 135;
      } else {
        lineHeading1 = lineHeading + -45;
        lineHeading2 = lineHeading + -135;
      }
      var pA = this.google.maps.geometry.spherical.computeOffset(
        P1,
        lineLength / 2.2,
        lineHeading1
      );
      var pB = this.google.maps.geometry.spherical.computeOffset(
        P2,
        lineLength / 2.2,
        lineHeading2
      );

      var curvedLine = new this.bezierCurve(P1, pA, pB, P2, 0.01, map);
      console.log(curvedLine);
      return curvedLine;
    },
    expandRow(item) {
      let findIndex = this.expanded.findIndex((x) => x.id == item.id);
      if (findIndex == -1) {
        this.expanded = [item];
      } else {
        this.expanded.splice(findIndex, 1);
      }
    },
    async expandLegs(item) {
      await this.getSailingScheduleLegs(item);
      // this.selectedSchedule = item

      // // item.loading = true
      // if (!item.sailingScheduleLegs) {
      //     item.sailingScheduleLegs = []
      // }
      this.scheduleModal = true;

      // item.loading = false
    },
    favourited(id) {
      return this.favourites.map((x) => x.unLocodeId).includes(id);
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },

    async getSailingScheduleLegs(item) {
      this.loadingLegs.push(item.id);
      item.loading = true;
      this.loadingScheduleLegs = true;
      item.sailingScheduleLegs = await this.$API.getSailingScheduleLegs(
        item.id
      );
      let uniqueCodes = [
        ...new Set([
          ...item.sailingScheduleLegs.map((x) => x.portOfLoadValue),
          ...item.sailingScheduleLegs.map((x) => x.portOfDischargeValue),
        ]),
      ];
      let points = [];
      // for(let i=0;i<item.sailingScheduleLegs.length;i++){
      //     if(item.sailingScheduleLegs[i].originLatitude && item.sailingScheduleLegs[i].originLongitude && item.sailingScheduleLegs[i].destinationLatitude && item.sailingScheduleLegs[i].destinationLongitude){

      //     item.sailingScheduleLegs[i].curve = this.drawCurve({
      //         lat: item.sailingScheduleLegs[i].originLatitude,
      //         lng: item.sailingScheduleLegs[i].originLongitude
      //     }, {
      //         lat: item.sailingScheduleLegs[i].destinationLatitude,
      //         lng: item.sailingScheduleLegs[i].destinationLongitude
      //     })
      // }
      // }
      for (let i = 0; i < uniqueCodes.length; i++) {
        let obj = {
          leg: i + 1,
          locode: uniqueCodes[i],
        };
        let find = item.sailingScheduleLegs.find(
          (x) => x.portOfLoadValue == uniqueCodes[i]
        );
        if (!find) {
          find = item.sailingScheduleLegs.find(
            (x) => x.portOfDischargeValue == uniqueCodes[i]
          );
          obj.latitude = parseFloat(find.destinationLatitude);
          obj.longitude = parseFloat(find.destinationLongitude);
        } else {
          obj.latitude = parseFloat(find.originLatitude);
          obj.longitude = parseFloat(find.originLongitude);
        }
        if (obj.latitude && obj.longitude) {
          obj.marker = {
            lat: obj.latitude,
            lng: obj.longitude,
          };
        }
        points.push(obj);
      }
      points = points.filter((x) => x.marker);
      if (points.length > 0) {
        this.mapCenter = points[0].marker;
      }
      this.points = points;
      this.selectedSchedule = item;
      if (item.sailingScheduleLegs.length > 3) {
        item.firstSailingScheduleLegs = item.sailingScheduleLegs.slice(0, 3);
        item.secondSailingScheduleLegs = item.sailingScheduleLegs.slice(3);
      }
      this.loadingScheduleLegs = false;
      item.loading = false;
      this.loadingLegs.splice(this.loadingLegs.indexOf(item.id), 1);
    },
    async getCarriers() {
      this.loadingCarriers = true;
      this.carriers = await this.$API.getShippingLines();
      this.loadingCarriers = false;
    },
    async getFavouriteRoutes() {
      this.loadingFavourites = true;
      this.favourites = await this.$API.getFavouriteRoutes();
      this.loadingFavourites = false;
      if (this.activeRoute) {
        let find = this.favourites.find((x) => x.id == this.activeRoute.id);
        if (find) {
          this.activeRoute = find;
        } else {
          this.activeRoute = null;
        }
      }
    },
    async getSchedules() {
      if (this.scheduleSearchTimeout) {
        clearTimeout(this.scheduleSearchTimeout);
      }
      if (this.loadingSchedules && this.scheduleSearchQuery) {
        this.scheduleSearchQuery.abort();
      }
      this.loadingSchedules = true;
      let params = {
        ...this.params,
        origins: this.originPorts.map((x) => x.port.locode),
        destinations: this.destinationPorts.map((x) => x.port.locode),
        // portOfLoadValue: this.origin.locode,
        // finalDestinationValue: this.destination.locode,
      };
      this.scheduleSearchTimeout = setTimeout(async () => {
        this.scheduleSearchQuery = new AbortController();
        const signal = this.scheduleSearchQuery.signal;
        this.schedules = await this.$API.getLogLiveSailingSchedule({
          params: params,
          signal,
        });
        this.calculateFilteredResults();
        this.loadingSchedules = false;
      }, 300);
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.schedules
            // .filter(x => x[value])
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      // if (value == 'status') {
      //     result.push({ name: 'Unallocated', value: null })
      // }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    setPort(item) {
      if (this.portSearchType == "Origin") {
        this.originPorts.push({ port: item });
      } else {
        this.destinationPorts.push({ port: item });
        // this.destination = item
      }
      this.portSearchModal = false;
      this.portSearch = null;
      if (this.activeRoute) {
        this.routeChanges = true;
      }
      this.searchKey++;
      setTimeout(() => {
        this.searchMenu = true;
      }, 250);
      // this.$nextTick(() => {
      //     this.searchMenu = true
      // })
    },

    async removeFavourite() {
      this.removingFavourite = true;
      await this.$API.updateFavouriteRoute({
        id: this.activeRoute.id,
        isActive: false,
        isDeleted: true,
      });
      let find = this.favourites.findIndex((x) => x.id == this.activeRoute.id);
      this.favourites.splice(find, 1);
      this.activeRoute = null;
      this.$message.success("Removed from favourites");
      this.removingFavourite = false;
    },
    removePort(type, port) {
      if (type == "Origin") {
        let find = this.originPorts.findIndex((x) => x.port.id == port.port.id);
        this.originPorts.splice(find, 1);
        this.routeChanges = true;
        this.searchKey++;
      } else {
        let find = this.destinationPorts.findIndex(
          (x) => x.port.id == port.port.id
        );
        this.destinationPorts.splice(find, 1);
        this.routeChanges = true;
        this.searchKey++;
      }
    },
    removeRoutePort(port) {
      let find = this.activeRoute.ports.findIndex((x) => x.id == port.id);
      this.activeRoute.ports.splice(find, 1);
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.updateFilterValue++;
      localStorage.removeItem(
        "scheduleFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    setRoute(route) {
      this.routeChanges = false;
      if (!this.activeRoute || this.activeRoute.id != route.id) {
        this.activeRoute = route;
        this.originPorts = route.ports.filter((x) => x.type == "Origin");
        this.destinationPorts = route.ports.filter(
          (x) => x.type == "Destination"
        );
        this.searchKey++;
      } else {
        this.activeRoute = null;
        this.originPorts = [];
        this.destinationPorts = [];
        this.searchKey++;
      }
      // this.origin = route.origin
      // this.destination = route.destination
    },
    setDateType(type) {
      this.selectedDateType = type;
      switch (type) {
        case "week":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date(
              new Date().setDate(
                new Date().getDate() - new Date().getDay() + 14
              )
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "4_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(
                new Date().getDate() - new Date().getDay() + 28
              )
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.searchKey++;
    },
    async updateFavourite() {
      this.updatingFavourite = true;
      await this.$API.updateFavouriteRoute({
        id: this.activeRoute.id,
        ports: [
          ...this.originPorts.map((x) => ({
            id: x.id,
            type: "Origin",
            portId: x.port.id,
          })),
          ...this.destinationPorts.map((x) => ({
            id: x.id,
            type: "Destination",
            portId: x.port.id,
          })),
        ],
      });
      this.getFavouriteRoutes();
      this.routeChanges = false;
      this.updatingFavourite = false;
    },
    updateSearchKey() {
      this.searchKey++;
    },
  },
};
</script>
<style scoped>
.map-marker {
  /* position: relative; */
}

.map-marker::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  /* top: -5px; */
  bottom: -20px;
  left: 10px;
  border: 10px solid transparent;
  border-top: 17px solid black;
}

.container {
  width: 100%;
  height: 650px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

map > div {
  border-radius: 20px;
}
</style>

<style scoped>
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* th div {
    border: 0;
    min-height: 20px;
    min-width: 20px;
} */
</style>